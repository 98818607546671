import { Button, Heading, Image, Link, Text, VStack } from "@chakra-ui/react";
import Footer from "components/website/Footer";
import Navbar from "components/website/Navbar";
import Section from "components/website/Section";
import Seo from "components/website/Seo";
import NotFoundImage from "images/error_404.webp";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo />
      <Navbar />

      <Section
        id="not-found"
        pt={[2, 2, 2, 2]}
        display="flex"
        flexDirection="column"
        gap={12}
        alignItems="center"
      >
        <Image
          src={NotFoundImage}
          maxW="4xl"
          alt="Illustrazione di matrimonio all'aperto con dettagli giocosi in stile 404"
          loading="eager"
          borderRadius="lg"
        />
        <VStack textAlign="center" gap={6} maxW="2xl">
          <Heading as="h1" size="3xl">
            {t("not-found-title")}
          </Heading>

          <Text>{t("not-found-description")}</Text>

          <Button as={Link} href="/" colorScheme="primary">
            {t("not-found-cta")}
          </Button>
        </VStack>
      </Section>

      <Footer />
    </>
  );
};

export default NotFound;
