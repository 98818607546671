import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: {
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    primary: {
      50: "#F0EFFA",
      100: "#DCDCF4",
      200: "#BAB9E9",
      300: "#9796DE",
      400: "#7977D4",
      500: "#5453C9",
      600: "#3937AF",
      700: "#2A2880",
      800: "#1C1B55",
      900: "#0E0D2B",
    },
    secondary: {
      50: "#FEF5EB",
      100: "#FEECD7",
      200: "#FDD9B0",
      300: "#FBC688",
      400: "#FAB361",
      500: "#F9A038",
      600: "#EE8207",
      700: "#B26205",
      800: "#774104",
      900: "#3B2102",
    },
    neutral: {
      0: "#FFFFFF",
      25: "#F8FAFC",
      50: "#F1F5F9",
      100: "#E2E8F0",
      200: "#CBD5E1",
      300: "#94A3B8",
      400: "#64748B",
      500: "#475569",
      600: "#334155",
      700: "#1E293B",
      800: "#0F172A",
      900: "#020617",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "medium",
        lineHeight: 1.4,
      },
    },
    Heading: {
      baseStyle: {
        color: "primary.800",
        fontWeight: "semibold",
      },
    },
    Text: {
      baseStyle: {
        color: "neutral.900",
      },
    },
  },
});

export default theme;
