import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface SeoProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
}

const Seo = ({
  title = "rsVIP",
  description = "L'alleato ideale per ottimizzare la gestione degli RSVP, risparmiando tempo prezioso e garantendo l'accuratezza necessaria per pianificare ogni dettaglio dell'evento.",
  image = "images/cover.jpg",
  article = false,
}: SeoProps) => {
  const { pathname } = useLocation();

  const titleTemplate = "%s - Guest Management Platform";

  const siteUrl = window.location.origin;

  const seo = {
    title: title,
    description: description,
    image: new URL(image, siteUrl).toString(),
    url: new URL(pathname, siteUrl).toString(),
  };

  const schemaOrgJSONLD = {
    "@context": "https://www.schema.org",
    "@type": "WebSite",
    name: title,
    url: siteUrl,
    description: seo.description,
    image: seo.image,
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={article ? "article" : "website"} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default Seo;
