import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { RouteProps, useLoaderData } from "react-router-dom";
import SectionSwitch from "sections/SectionSwitch";
import { Website } from "./types";

const WebsiteBuilder: FC<RouteProps> = () => {
  const website = useLoaderData() as Website;

  return (
    <Box bgColor={website.theme.background} color={website.theme.color}>
      {website.sections.map((section, index) => (
        <SectionSwitch key={`${section.type}_${index}`} section={section} />
      ))}
    </Box>
  );
};

export default WebsiteBuilder;
