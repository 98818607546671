import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Text as ChakraText,
} from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import { FunctionComponent } from "react";
import type { EventsProps } from "./types";

const Events: FunctionComponent<EventsProps> = ({ events }) => {
  return (
    <Box p="10" display="flex" flexDir="column" gap={20}>
      {events.map((event, index) => {
        const direction = index % 2 ? "row-reverse" : "row";
        const eventDate = new Timestamp(
          event.date.seconds,
          event.date.nanoseconds
        );

        return (
          <Container maxW="container.md" key={event.title}>
            <Stack
              gap={8}
              direction={["column", "column", direction]}
              alignItems="center"
            >
              <Image
                src={event.image}
                w={["full", "full", 80]}
                h={80}
                fit="cover"
                flexShrink={0}
                borderRadius="md"
              />
              <Box flexGrow={1}>
                <ChakraText>
                  {eventDate.toDate().toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </ChakraText>
                <Heading fontWeight="500">{event.title}</Heading>
                <ChakraText>{event.description}</ChakraText>
              </Box>
            </Stack>
          </Container>
        );
      })}
    </Box>
  );
};

export default Events;
