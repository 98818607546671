import Footer from "components/website/Footer";
import Navbar from "components/website/Navbar";
import Seo from "components/website/Seo";
import BenefitsSection from "./components/BenefitsSection";
import CtaSection from "./components/CtaSection";
import FaqSection from "./components/FaqSection";
import FeaturesSection from "./components/FeaturesSection";
import HeroSection from "./components/HeroSection";
import NumbersSection from "./components/NumbersSection";
import ReviewsSection from "./components/ReviewsSection";

const Home = () => (
  <>
    <Seo />
    <Navbar />

    <HeroSection />

    <BenefitsSection />
    <FeaturesSection />
    <NumbersSection />
    <ReviewsSection />
    <CtaSection />
    <FaqSection />

    <Footer />
  </>
);

export default Home;
