import { Box, Center, Container, Text as ChakraText } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import type { TextProps } from "./types";

const Text: FunctionComponent<TextProps> = ({
  texts,
  backgroundImage,
  theme,
}) => {
  return (
    <Center
      position="relative"
      p="10"
      minH="max(37.5vw, 300px)"
      bg={theme?.background}
    >
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        bgImage={`url('${backgroundImage?.url}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        filter="saturate(100%) hue-rotate(0deg) brightness(100%) opacity(100%) blur(0px)"
      ></Box>
      <Container maxW="container.md" display="flex" flexDir="column" gap={4}>
        {texts.map((text) => (
          <ChakraText
            key={text.content}
            color={text.theme?.color}
            fontSize={text.theme?.fontSize}
            fontWeight={text.theme?.fontWeight}
            textAlign={text.theme?.textAlign}
            zIndex={1}
          >
            {text.content}
          </ChakraText>
        ))}
      </Container>
    </Center>
  );
};

export default Text;
