import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import Section from "components/website/Section";
import dashboard from "images/hero/dashboard.png";
import { Trans, useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <Box px={6} py={12}>
      <Section
        id="hero"
        backgroundColor="primary.50"
        textAlign="center"
        borderRadius="2xl"
        pb={[0, 0, 0, 0]}
      >
        <Flex
          flexDirection="column"
          gap={6}
          alignItems="center"
          px={[0, 10, 12]}
          py={12}
        >
          <Heading
            as="h1"
            maxW="4xl"
            mx="auto"
            fontSize={["5xl", "7xl", "8xl"]}
            lineHeight={1}
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            <Trans
              i18nKey="home-hero-section-title"
              components={[
                <Text as="span" color="secondary.500">
                  highlight
                </Text>,
              ]}
            />
          </Heading>
          <Text maxW="md">{t("home-hero-section-description")}</Text>
          <Button
            as={Link}
            href="mailto:info@rsvip.it"
            colorScheme="primary"
            size="lg"
          >
            {t("home-hero-section-cta")}
          </Button>
        </Flex>

        <Image alt="rsVIP dashboard" w="full" src={dashboard} />

        <Box
          position="absolute"
          height={[95, 180, 180, 325]}
          bottom={0}
          left={0}
          right={0}
          bgGradient="linear(to-b, transparent, white)"
        />
      </Section>
    </Box>
  );
};

export default HeroSection;
