import { Flex, Text } from "@chakra-ui/react";
import Section from "components/website/Section";
import { useTranslation } from "react-i18next";

const numbers = Array.from({ length: 3 }).map((_, i) => ({
  value: `home-numbers-section-number-${i + 1}-value`,
  description: `home-numbers-section-number-${i + 1}-description`,
}));

const NumbersSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      id="reviews"
      backgroundColor="secondary.500"
      display="grid"
      gridTemplateColumns={["1fr", "1fr", "repeat(3, 1fr)"]}
      gap={12}
    >
      {numbers.map((number) => (
        <Flex
          key={number.value}
          flexDirection="column"
          gap={2}
          textAlign={["center", "center", "left"]}
        >
          <Text fontSize="5xl" fontWeight="bold" color="white">
            {t(number.value)}
          </Text>

          <Text color="white">{t(number.description)}</Text>
        </Flex>
      ))}
    </Section>
  );
};

export default NumbersSection;
