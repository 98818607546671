import { Box, ChakraProps, Container } from "@chakra-ui/react";
import React, { FC } from "react";

interface SectionProps extends ChakraProps {
  id: string;
  backgroundColor?: string;
  children: React.ReactNode;
}

const Section: FC<SectionProps> = ({
  id,
  backgroundColor,
  bgGradient,
  borderRadius,
  children,
  ...containerProps
}) => {
  return (
    <Box
      id={id}
      backgroundColor={backgroundColor}
      bgGradient={bgGradient}
      borderRadius={borderRadius}
      position="relative"
      px={[6, 6, 8]}
    >
      <Container maxW="6xl" px={0} py={[16, 20, 20, 24]} {...containerProps}>
        {children}
      </Container>
    </Box>
  );
};

export default Section;
