import { Box, Center, Heading } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import type { HeroProps } from "./types";

const Hero: FunctionComponent<HeroProps> = ({ title, image }) => {
  // const backgroundFilters = {
  //   saturate: "100%",
  //   "hue-rotate": "0deg",
  //   brightness: "100%",
  //   opacity: "100%",
  //   blur: "0px",
  // };

  return (
    <Center h="100vh" position="relative" p="10">
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        bgImage={`url('${image}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        filter="saturate(100%) hue-rotate(0deg) brightness(100%) opacity(100%) blur(0px)"
      ></Box>
      <Heading
        maxW="container.sm"
        as="h1"
        size="4xl"
        textAlign="center"
        zIndex={1}
        // fontFamily="Love Light"
        // fontSize=""
      >
        {title}
      </Heading>
    </Center>
  );
};

export default Hero;
