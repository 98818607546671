import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import firebaseApp from "../../firebase";

const db = getFirestore(firebaseApp);
export const retrieveWebsite = async ({ slug }: { slug: string }) => {
  const websiteCollection = collection(db, "websites");

  const queryWebsiteRef = query(websiteCollection, where("slug", "==", slug));

  const websitesSnapshot = await getDocs(queryWebsiteRef);

  if (websitesSnapshot.docs.length) {
    return websitesSnapshot.docs[0].data();
  } else {
    throw new Error("project-retrieve/no-such-document");
  }
};

const api = {
  retrieve: retrieveWebsite,
};

export default api;
