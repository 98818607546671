import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Intro from "components/blocks/Intro";
import Section from "components/website/Section";
import { useTranslation } from "react-i18next";
import {
  PiClipboardText,
  PiClockCounterClockwise,
  PiSliders,
} from "react-icons/pi";

const icons = [
  <PiClockCounterClockwise size="1.5rem" />,
  <PiClipboardText size="1.5rem" />,
  <PiSliders size="1.5rem" />,
];

const benefits = Array.from({ length: 3 }).map((_, i) => ({
  title: `home-benefits-section-benefit-${i + 1}-title`,
  description: `home-benefits-section-benefit-${i + 1}-description`,
  icon: icons[i],
}));

const BenefitsSection = () => {
  const { t } = useTranslation();

  return (
    <Section id="benefits" display="flex" gap={12} flexDirection="column">
      <Intro
        name={t("home-benefits-section-name")}
        title={t("home-benefits-section-title")}
        description={t("home-benefits-section-description")}
      />

      <Flex gap={6} flexDirection={["column", "column", "row"]}>
        {benefits.map((benefit, index) => (
          <Flex
            key={index}
            flexDirection="column"
            alignItems="flex-start"
            flex={1}
            gap={6}
            p={6}
            borderRadius="lg"
            borderWidth={1}
            borderColor="neutral.100"
          >
            <Box
              p={3}
              borderRadius="md"
              backgroundColor="neutral.25"
              color="secondary.600"
            >
              {benefit.icon}
            </Box>
            <Flex flexDirection="column" gap={1}>
              <Heading as="h3" size="md">
                {t(benefit.title)}
              </Heading>
              <Text fontSize="sm">{t(benefit.description)}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Section>
  );
};

export default BenefitsSection;
