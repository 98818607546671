import { FunctionComponent } from "react";
import Events from "../Events";
import Hero from "../Hero";
import Text from "../Text";
import { SectionSwitchProps } from "./types";

const SectionSwitch: FunctionComponent<SectionSwitchProps> = ({ section }) => {
  switch (section.type) {
    case "HERO":
      return <Hero title={section.title} image={section.image} />;
    case "TEXT":
      return (
        <Text
          texts={section.texts}
          backgroundImage={section.backgroundImage}
          theme={section.theme}
        />
      );
    case "EVENTS":
      return <Events events={section.events} />;
    default:
      return null;
  }
};

export default SectionSwitch;
